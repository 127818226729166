import React from "react"
import {graphql, useStaticQuery} from "gatsby"

import SEO from "../components/seo"
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";
import LayoutPediatra from "../components/layout_pediatra";
import Modal from "react-modal";

const IndexPage = () => {
  const interpolation = require('interpolate-json').interpolation;
  const data = useStaticQuery(graphql`
    query {
      app: file(relativePath: { eq: "ios.svg" }) {
        publicURL
      }
      app_sm: file(relativePath: { eq: "app-sm.svg" }) {
        publicURL
      }
      app_store: file(relativePath: { eq: "apple-store.svg" }) {
        publicURL
      }
      google_play: file(relativePath: { eq: "google-play.svg" }) {
        publicURL
      }
      pediatrician_img: file(relativePath: { eq: "pediatrician.svg" }) {
        publicURL
      }
      i_circle: file(relativePath: { eq: "icons/circle.svg" }) {
        publicURL
      }
      i_lock: file(relativePath: { eq: "icons/lock.svg" }) {
        publicURL
      }
      i_sec: file(relativePath: { eq: "icons/sec.svg" }) {
        publicURL
      }
      i_text: file(relativePath: { eq: "icons/text.svg" }) {
        publicURL
      }
      i_cloud: file(relativePath: { eq: "icons/cloud.svg" }) {
        publicURL
      }
      logo: file(relativePath: { eq: "logo-camilla.svg" }) {
        publicURL
      }
      allDataJson {
        nodes {
          activate
          pediatricians {
            title
            desc
            desc_second
            desc_sub_camilla
            desc_low
            desc_company
            desc_mobile
            section_2_intro_mobile_title
            section_2_intro_mobile_desc
            section_2 {
             title
             desc
            }
            features_title
            features_camilla {
             title
             desc
            }
            faq {
              title
              qa_camilla {
                q
                a
              }
            }
            upcoming_features {
              title
              list
            }
            footnote
          }
        }
      }
    }
  `)
  Modal.setAppElement(`#___gatsby`);

  const values = {
    companyName: 'Camilla',
  };

  return (
    <LayoutPediatra>
      <SEO title="Pediatra" />
      <div className={"page pediatricians bg-white overflow-hidden"}>
        <Row className={"bg-white justify-content-center pt-page d-none d-sm-none d-md-flex"}>
          <Col md={{ span: 7, order: 1}} lg={5} xl={5} className={"text"}>
            <h3 className={"nobold"} dangerouslySetInnerHTML={{__html: data.allDataJson.nodes[0].pediatricians.desc}} />
            <h3 className={"nobold nospace"} dangerouslySetInnerHTML={{__html: data.allDataJson.nodes[0].pediatricians.desc_second}} />
            <h3 className={"nobold"} dangerouslySetInnerHTML={{__html: data.allDataJson.nodes[0].pediatricians.desc_low}} />
            <h3 dangerouslySetInnerHTML={{__html: data.allDataJson.nodes[0].pediatricians.desc_sub_camilla}} />
            <div className={"d-flex justify-content-between p-40"}>
              <div className={"mr-1"}>
                <p rel="noreferrer" dangerouslySetInnerHTML={{__html: data.allDataJson.nodes[0].pediatricians.desc_company}} className={"nospace"}/>
                <img style={{ height: "65px" }} src={data.logo.publicURL} alt={"Logo company"}/>
              </div>
              <div className={"d-flex justify-content-end mt-5"}>
                <a href="https://apps.apple.com/app/id1512934824" target={"_blank"} rel="noreferrer"><img style={{ height: "48px" }} src={data.app_store.publicURL} alt={"App Store"}/></a>
                <a href="https://play.google.com/store/apps/details?id=it.miopediatra" target={"_blank"} rel="noreferrer"><img style={{ height: "48px" }} className={"ml-2"} src={data.google_play.publicURL} alt={"Google Play"}/></a>
              </div>
            </div>
          </Col>
          <Col md={{ span: 4, order: 2}} lg={3} xl={3} className={""}>
            <img src={data.app.publicURL} alt={"App"} style={{ maxHeight: '640px' }}/>
          </Col>
        </Row>

        <Row className={"bg-white pt-5 justify-content-center d-md-none"}>
          <Col xs={{ span: 10, order: 2}} style={{ marginTop: "-14vh" }}>
            <h3 className={"nobold"} dangerouslySetInnerHTML={{__html: data.allDataJson.nodes[0].pediatricians.desc}} />
            <h3 className={"nobold nospace"} dangerouslySetInnerHTML={{__html: data.allDataJson.nodes[0].pediatricians.desc_second}} />
            <h3 className={"nobold"} dangerouslySetInnerHTML={{__html: data.allDataJson.nodes[0].pediatricians.desc_low}} />
            <h3 dangerouslySetInnerHTML={{__html: data.allDataJson.nodes[0].pediatricians.desc_sub}} />
            <div className={"mt-2 p-50"}>
              <p rel="noreferrer" dangerouslySetInnerHTML={{__html: data.allDataJson.nodes[0].pediatricians.desc_company}} className={"nospace"}/>
              <img className={""} style={{ height: "65px" }} src={data.logo.publicURL} alt={"Logo company"}/>
            </div>
            <div className={"d-flex justify-content-start"}>
              <a href="https://apps.apple.com/app/id1512934824" target={"_blank"} rel="noreferrer"><img style={{ height: "48px" }} src={data.app_store.publicURL} alt={"App Store"}/></a>
              <a href="https://play.google.com/store/apps/details?id=it.miopediatra" target={"_blank"} rel="noreferrer"><img style={{ height: "48px" }} className={"ml-2"} src={data.google_play.publicURL} alt={"Google Play"}/></a>
            </div>
          </Col>
          <Col xs={{ span: 12, order: 1}} className={"d-md-none"}>
            <img src={data.app_sm.publicURL} alt={"App"} className={"app-mobile pt-5"}/>
          </Col>
        </Row>

        <Row className={"justify-content-center bg-light fixed-mt section-2"}>
          <Col xs={10} className={"col-img d-md-none"}>
              <h1 dangerouslySetInnerHTML={{__html: data.allDataJson.nodes[0].pediatricians.section_2_intro_mobile_title}}/>
              <p dangerouslySetInnerHTML={{__html: data.allDataJson.nodes[0].pediatricians.section_2_intro_mobile_desc}}/>
          </Col>
          <Col xs={{ span: 6 }} className={"col-img"}>
            <img src={data.pediatrician_img.publicURL} alt={"App"} className={"pediatrician-image"}/>
          </Col>
          <Col xs={10} lg={{ span: 4}}>
            <Col xs={10} lg={{ span: 8, offset: 4}}>
              <img src={data['i_lock'].publicURL} alt={"Feature"} className={"pediatrician-image section-2-circle"} style={{ maxWidth: "80px" }}/>
              <h3 dangerouslySetInnerHTML={{__html: data.allDataJson.nodes[0].pediatricians.section_2[0].title}}/>
              <p dangerouslySetInnerHTML={{__html: data.allDataJson.nodes[0].pediatricians.section_2[0].desc}} />
            </Col>
            <Col xs={10} lg={{ span: 8}} className={"section-2-circle-col-2"}>
              <img src={data['i_sec'].publicURL} alt={"Feature"} className={"pediatrician-image section-2-circle"} style={{ maxWidth: "80px" }}/>
              <h3 dangerouslySetInnerHTML={{__html: data.allDataJson.nodes[0].pediatricians.section_2[1].title}}/>
              <p dangerouslySetInnerHTML={{__html: interpolation.expand(data.allDataJson.nodes[0].pediatricians.section_2[1].desc, values)}} />
            </Col>
          </Col>
          <Col md={2}/>
        </Row>

        <Row className={"bg-white section-3 pb-4 py-5 justify-content-center"}>
          <Col xs={10} md={{ span: 8}}>
          <h1 style={{ marginTop: "4rem", marginBottom: "5rem" }} dangerouslySetInnerHTML={{__html: data.allDataJson.nodes[0].pediatricians.features_title}}/>
            { data.allDataJson.nodes[0].pediatricians.features_camilla.map((feat, i) => (
          <Row className={"my-3"} style={{ minHeight: "180px" }}>
              <Col key={i} xs={{ span: 10 }} className={"p-3"}>
                <h1 className={"position-absolute text-blue"} style={{fontSize: "12rem", marginTop: "-4rem", opacity: ".1"}} dangerouslySetInnerHTML={{__html: i+1}}></h1>
                <div className={"ml-5"}>
                  <h2 className={"text-blue"} dangerouslySetInnerHTML={{__html: feat.title}}/>
                  <div className={"text-small"} dangerouslySetInnerHTML={{__html: feat.desc.includes("{companyName}") ? interpolation.expand(feat.desc, values) : feat.desc}}/>
                </div>
              </Col>
          </Row>
            ))}
          </Col>
        </Row>

        <Row className={"justify-content-center bg-light section-4 py-5"}>
          <Col xs={10} md={{ span: 8}}>
            <Card>
              <Card.Header className={"border-0"}>
                <h3 className={"my-3"} dangerouslySetInnerHTML={{__html: data.allDataJson.nodes[0].pediatricians.faq.title}}/>
              </Card.Header>
              <Card.Body className={"p-0 qas"}>
                <Accordion>
                  { data.allDataJson.nodes[0].pediatricians.faq.qa_camilla.map((qa, key) => (
                    <div key={key+1} className={"border-bottom"}>
                      <Accordion.Toggle as={Card.Header} variant="link" eventKey={key+1} className={"my-2 border-0 font-weight-bold"} dangerouslySetInnerHTML={{__html: interpolation.expand( qa.q, values) }} />
                      <Accordion.Collapse eventKey={key+1} >
                        <Card.Body className={"pt-0"} dangerouslySetInnerHTML={{__html: interpolation.expand(qa.a, values) }} />
                      </Accordion.Collapse>
                    </div>
                  ))}
                </Accordion>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className={"bg-white section-5 justify-content-center pt-5 pb-4"}>
          <Col xs={10} md={{ span: 8}} className={"text-center"}>
            <p className={"mb-2"} dangerouslySetInnerHTML={{__html: data.allDataJson.nodes[0].pediatricians.footnote}} />
          </Col>
        </Row>

      </div>
    </LayoutPediatra>
  )
}

export default IndexPage
